










































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import {
  getJueses,
  AddJueses,
  DeleteJueses,
  EditJueses,
} from "@/request/manage";
import { GetZhuantis } from "@/request/schema";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private data: any = [];
  private editData: any = {};
  private ifShowEdit: any = false;
  private tree: any = [
    [
      {
        label: "首页",
        children: [
          {
            name: "首页搜索",
            label: "首页",
          },
        ],
      },
    ],
    // [],
    [
      {
        label: "实体库",
        children: [
          {
            name: "实体库",
            label: "实体库",
          },
        ],
      },
    ],
    [
      {
        label: "原文库",
        children: [
          {
            name: "古籍",
            label: "古籍",
          },
        ],
      },
    ],
    [
      {
        label: "知识校验",
        children: [
          {
            name: "原文管理",
            label: "原文管理",
          },
          {
            name: "原文校验",
            label: "原文校验",
          },
          {
            name: "原文审核",
            label: "原文审核",
          },
          {
            name: "原文切分",
            label: "原文切分",
          },
          {
            name: "实体管理",
            label: "实体管理",
          },
          {
            name: "实体校验",
            label: "实体校验",
          },
          {
            name: "schema管理",
            label: "schema管理",
          },
        ],
      },
    ],
    // [
    //   {
    //     label: "实体校验",
    //     children: [
    //       {
    //         name: "实体校验新增概念",
    //         label: "新增概念",
    //       },
    //       {
    //         name: "实体校验校验",
    //         label: "校验",
    //       },
    //       {
    //         name: "实体校验删除",
    //         label: "删除",
    //       },
    //       {
    //         name: "实体校验查看校验痕迹",
    //         label: "查看校验痕迹",
    //       },
    //     ],
    //   },
    //   {
    //     label: "原文校验",
    //     children: [
    //       {
    //         name: "原文校验校验",
    //         label: "校验",
    //       },
    //       {
    //         name: "原文校验查看校验痕迹",
    //         label: "查看校验痕迹",
    //       },
    //     ],
    //   },
    //   {
    //     label: "校验管理",
    //     children: [
    //       {
    //         name: "校验管理锁定",
    //         label: "锁定",
    //       },
    //       {
    //         name: "校验管理查看校验痕迹",
    //         label: "查看校验痕迹",
    //       },
    //     ],
    //   },
    //   {
    //     label: "属性来源",
    //     children: [
    //       {
    //         name: "属性来源新增属性来源",
    //         label: "新增属性来源",
    //       },
    //       {
    //         name: "属性来源编辑",
    //         label: "编辑",
    //       },
    //       {
    //         name: "属性来源删除",
    //         label: "删除",
    //       },
    //     ],
    //   },
    //   {
    //     label: "Schema管理",
    //     children: [
    //       {
    //         name: "schema语义类型",
    //         label: "语义类型",
    //       },
    //       {
    //         name: "schema属性类型",
    //         label: "属性类型",
    //       },
    //       {
    //         name: "schema关系类型",
    //         label: "关系类型",
    //       },
    //     ],
    //   },
    // ],
    [
      {
        label: "任务管理",
        children: [
          {
            name: "任务管理新增任务分类",
            label: "新增任务分类",
          },
          {
            name: "任务管理新增标注任务",
            label: "新增标注任务",
          },
          {
            name: "任务管理查看",
            label: "查看",
          },
          {
            name: "任务管理分配",
            label: "分配",
          },
          {
            name: "任务管理删除",
            label: "删除",
          },
          {
            name: "任务管理标注进度",
            label: "标注进度",
          },
        ],
      },
      {
        label: "任务标注",
        children: [
          {
            name: "任务标注查看",
            label: "查看",
          },
          {
            name: "任务标注标注",
            label: "标注",
          },
          {
            name: "任务标注标注进度",
            label: "标注进度",
          },
        ],
      },
      {
        label: "任务审核",
        children: [
          {
            name: "任务审核查看",
            label: "查看",
          },
          {
            name: "任务审核标注进度",
            label: "标注进度",
          },
        ],
      },
      {
        label: "标注结果库",
        children: [
          {
            name: "标注结果库查看",
            label: "查看",
          },
          {
            name: "标注结果库标注进度",
            label: "标注进度",
          },
        ],
      },
      {
        label: "进度统计",
        children: [
          {
            name: "进度统计查看",
            label: "查看",
          },
        ],
      },
    ],
    [
      {
        label: "知识图谱",
        children: [
          {
            name: "知识图谱搜索",
            label: "知识图谱",
          },
        ],
      },
    ],
    // [
    //   {
    //     label: "专题库",
    //     children: [],
    //   },
    // ],
    [
      {
        label: "用户管理",
        children: [
          {
            name: "用户管理新增用户",
            label: "新增用户",
          },
          {
            name: "用户管理编辑",
            label: "编辑",
          },
          {
            name: "用户管理重置密码",
            label: "重置密码",
          },
          {
            name: "用户管理禁用",
            label: "禁用(启用)",
          },
        ],
      },
      {
        label: "角色管理",
        children: [
          {
            name: "角色管理新增角色",
            label: "新增角色",
          },
          {
            name: "角色管理编辑",
            label: "编辑",
          },
          {
            name: "角色管理删除",
            label: "删除",
          },
        ],
      },
    ],
  ];
  private cTree: any = [];
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getJueses(this)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private get Allku() {
    return this.$store.state.kuList;
  }
  private kuList() {
    const num: any = 10;
    let data: any = [];
    this.$store.state.kuList.forEach((items: any, index: any) => {
      const obj = {
        name: items.table_name + "库搜索",
        label: items.table_name,
      };
      if ((index + 1) % num === 1) {
        if (data.length == 0) {
          data.push({
            label: "知识库",
            children: [],
          });
        } else {
          data.push({
            label: "",
            children: [],
          });
        }
      }
      data[data.length - 1].children.push(obj);
    });
    // this.tree[1] = data;
  }
  private jumpCompose(item: any) {
    if (!this.getIsShowQuanxian("角色管理编辑")) {
      return;
    }
    const params: any = {
      params: {
        role_id: item._id,
      },
    };
    getJueses(this, params).then((res) => {
      this.editData = res;
      this.cTree = JSON.parse(JSON.stringify(this.tree));
      this.ifShowEdit = true;
    });
  }
  private add() {
    this.cTree = [];
    setTimeout(() => {
      this.cTree = JSON.parse(JSON.stringify(this.tree));
    }, 100);
    this.editData = {};
    this.ifShowEdit = true;
    this.$forceUpdate();
  }
  private deleteOnde(item: any) {
    if (!this.getIsShowQuanxian("角色管理删除")) {
      return;
    }
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            role_id: item._id,
          },
        };
        DeleteJueses(this, params).then((res: any) => {
          this.$message.success("删除成功");
          this.getData();
        });
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  private save() {
    if (!this.editData.name) {
      this.$message.warning("请输入角色名称");
      return;
    }
    let hasJuese: any = false;
    this.cTree.forEach((ele: any) => {
      ele.forEach((items: any) => {
        items.children.forEach((item: any) => {
          if (this.editData[item.name]) {
            hasJuese = true;
          }
        });
      });
    });
    if (!hasJuese) {
      this.$message.warning("请配置相关功能");
      return;
    }
    if (!this.editData._id) {
      AddJueses(this, this.editData).then((res: any) => {
        this.$message.success("新增成功");
        this.ifShowEdit = false;
        this.getData();
      });
    } else {
      this.editData.role_id = this.editData._id;
      EditJueses(this, this.editData).then((res: any) => {
        this.$message.success("编辑成功");
        this.getUser();
        this.ifShowEdit = false;
        this.getData();
      });
    }
  }
  private getZhuanti() {
    let arr: any = [];
    GetZhuantis(this).then((res: any) => {
      res.forEach((ele: any) => {
        const obj = {
          name: "专题库" + ele,
          label: ele,
        };
        arr.push(obj);
      });
      this.tree.forEach((element: any) => {
        element.forEach((e: any) => {
          if (e.label == "专题库") {
            e.children = arr;
          }
        });
      });
      this.$forceUpdate();
    });
  }
  
  mounted() {
    this.getData();
    this.kuList();
    // this.getZhuanti();
  }
}
